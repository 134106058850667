.info-card {
    min-width: 260px;
    width: 100%;
    height: 100%;
    max-height: inherit;
    overflow-x: hidden;
}

.info-card > div {
    overflow-y: scroll;
    margin-right: -16px;
}
