.map-container {
    width: 100%;
    height: 100%;
    position: absolute;
}

.map-container-responsive {
    width: auto;
    height: 400px !important;
    position: relative;
}

.floating-control-btn {
    right: 20px;
    bottom: 40px;
    opacity: 0.8;
    margin: 5px;
}

.floating-control-container {
    position: absolute;
    bottom: 0;
    right: 0;
}

.floating-control-container > div {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    justify-content: flex-end;
}

.info-bar-container {
    position: absolute;
    left: 0;
    bottom: 0;
    overflow: hidden;
    width: 265px;
    height: 100%;
    transition: all ease 0.2s;
}

.info-bar {
    position: absolute;
    height: 100%;
    width: calc(100% - 25px);
    left: 0;
    bottom: 0;
    overflow-x: visible;
    background-color: white;
    transition: all ease 0.2s;
}

.info-bar > .info-bar-content {
    width: 100%;
    height: 100%;
    overflow: hidden auto;
}

.info-bar-switch {
    position: absolute !important;
    background-color: #1890ffaa;
    right: -25px;
    height: 100%;
    width: 25px;
    color: blue;
    cursor: pointer;
    z-index: 2;
    transition: all ease 0.2s;
}

.info-bar-switch:hover {
    background-color: #1890ff;
}

.info-bar-switch > svg {
    position: absolute;
    color: white;
    top: 50%;
    transition: transform ease 0.4s;
    transform: translateX(-50%) rotate(0);
}

.info-bar-hidden {
    width: 25px;
}

.info-bar-hidden > .info-bar > .info-bar-content {
    width: 260px;
    transform: translateX(-100%);
}

.info-bar-hidden > .info-bar > .info-bar-switch {
    background-color: #1890ff44;
}

.info-bar-hidden > .info-bar > .info-bar-switch:hover {
    background-color: #1890ffaa;
}

.info-bar-hidden > .info-bar > .info-bar-switch > svg {
    transform: translateX(-50%) rotate(180deg);
}

@media (max-width: 576px) {
    .info-bar-container {
        width: 100%;
        max-height: 70vh;
        min-height: 265px;
    }
    .info-bar {
        height: calc(100% - 25px);
        width: 100%;
        padding: 0.5rem;
    }
    .info-bar-switch {
        width: 100%;
        height: 25px;
        top: -25px;
        right: 0;
    }
    .info-bar-switch > svg {
        top: 0;
        transform: translateY(50%) rotate(-90deg);
    }
    .info-bar-hidden {
        height: 25px;
        min-height: unset;
    }
    .info-bar-hidden > .info-bar {
        width: 100%;
        transform: translateY(100%);
    }
    .info-bar-hidden > .info-bar > .info-bar-switch {
        background-color: #1890ffcc;
    }
    .info-bar-hidden > .info-bar > .info-bar-switch > svg {
        transform: translateY(50%) rotate(90deg);
    }
}

@media (max-width: 576) {
    .map-container-responsive {
        width: 400px;
    }
}
